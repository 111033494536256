import { useState, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';

import theme from '@/index.module.scss';
import { authService } from '@/services';

export default function LoginForm() {
  const [formData, setFormData] = useState<any>({});

  const [state, setState] = useState<'FORM' | 'LOGGED_IN' | 'LOGGING_IN' | 'ERROR'>('FORM');
  const redirectPathRef = useRef('/');
  const doLogin = (form: any) => {
    console.log('Loggingin');

    setState('LOGGING_IN');

    authService.loginWithUserAndPassword(formData.username, formData.password).then(
      value => {
        setState('LOGGED_IN');
        const redirectPath = localStorage.getItem('redirectPath') || '/';
        redirectPathRef.current = redirectPath;
        localStorage.removeItem('redirectPath');
      },
      reason => {
        setState('ERROR');
      },
    );

    form.preventDefault();
  };

  const handleChange = (event: any) => {
    formData[event.target.name] = event.target.value;

    setFormData({
      ...formData,
    });
  };

  if (state === 'ERROR')
    return (
      <div className={'alert alert-danger text-center'}>
        <div>Login failed </div>
        <button
          onClick={event => setState('FORM')}
          className={'btn btn-primary btn-sm'}
          style={{ fontFamily: 'Inter' }}
        >
          {' '}
          Try Again
        </button>
      </div>
    );

  if (state === 'LOGGING_IN')
    return (
      <>
        <div className={'m-5'}>
          <BarLoader color={theme.primary} width={'100%'} />
        </div>{' '}
      </>
    );

  if (state === 'LOGGED_IN') return <Navigate to={redirectPathRef.current} />;

  return (
    <div>
      <form onSubmit={doLogin}>
        <div className={'card m-5'}>
          <div className={'card-body bg-dark'}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                className={'form-control'}
                type={'text'}
                name={'username'}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                className={'form-control'}
                type={'password'}
                name={'password'}
                onChange={handleChange}
              />
            </div>
            <input className={'btn btn-success'} type={'submit'} value={'Login'} />
          </div>
        </div>
      </form>
    </div>
  );
}
