import { SearchResult } from '@/model/news/search-result';
import { Article, ArticleUtil } from '@/model/article';
import KeywordBadge from '@/components/shared/keyword-badge';
import AgedDateFormat from '@/components/shared/aged-date-format';
import DateFormat from '@/components/shared/date-format';
import styles from '@/components/articles/article-list.module.scss';
import { useState } from 'react';
import ArticleModal from '@/components/articles/article-modal';
import GenreBadge from '@/components/shared/genre-badge';

interface Props {
  articles?: SearchResult<Article>[];
  onArticleClick: Function;
  colorizeDates?: boolean;
  showGenreTags?: boolean;
  hideAuthor?: boolean;
  showKeywords?: boolean;
}

export default function SearchResultList({ articles, colorizeDates = true }: Props) {
  const [selected, setSelected] = useState<Article>();

  return (
    <div className={'h-100 container-fluid'}>
      {articles?.map((value, index) => {
        let scale = 0.5;
        let hideAuthor = false;

        if (ArticleUtil.hasGenre(value.item, 'HEADLINE')) {
          scale = 5;
          hideAuthor = true;
        }
        if (ArticleUtil.hasGenre(value.item, 'BULLET')) {
          scale = 2;
          // eslint-disable-next-line
          hideAuthor = true;
        }

        const type = value.item.genre.find(value1 => value1.scheme === 'mnitype');

        return (
          <div
            key={'article-' + value.item.uri}
            className={`row mb-2 border-bottom`}
            onClick={event => setSelected(value.item)}
          >
            <div className={'col-3 align-top  text-muted '}>
              {colorizeDates ? (
                <AgedDateFormat
                  format={'MMM D, h:mma YYYY'}
                  date={value.item.firstcreated}
                  scale={scale}
                />
              ) : (
                <DateFormat format={'MMM D, h:mma YYYY'} date={value.item.firstcreated} />
              )}
            </div>

            <div className={`col align-top text-text-dark mb-1 ${styles.article}`}>
              <span className={'fw-bold'}> {value.item.headline}</span>
              <div className={' small text-muted font-monospace'}>
                {type && <GenreBadge genre={type} />}{' '}
                {value.score ? 'search score: ' + value.score?.toFixed(3) : ''}
              </div>

              {
                <div>
                  {value.item.keywords.map(s => (
                    <span className={'small'}>
                      <KeywordBadge keyword={s} />{' '}
                    </span>
                  ))}
                </div>
              }
            </div>
          </div>
        );
      })}

      <ArticleModal onClose={() => setSelected(undefined)} articleId={selected?.uri} />
    </div>
  );
}
