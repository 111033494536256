import { useEffect, useRef, useState } from 'react';
import { addClickHandlersToElement } from '@/components/shared/pdf-viewer';
import styles from '@/components/articles/article-view.module.scss';
import useSubscription from '@/hooks/use-subscription';
import { messageService } from '@/services';


function addTableResponsiveDiv(table: HTMLTableElement) {
  const div = document.createElement('div');

  div.className = 'table-responsive';

  table.parentElement?.replaceChild(div, table);
  div.appendChild(table);

  let classes = table.getAttribute('class') || '';

  if (!classes.includes('table')) classes = `${classes} table`;

  table.setAttribute('class', classes);
}

export default function ArticleBodyDisplay({
  xhtml,
  pinnedWidget,
}: {
  xhtml: string;
  pinnedWidget?: boolean;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [toggle, setToggle] = useState<boolean>(true);

  useEffect(() => {
    if (ref && xhtml) {
      if (!ref.current) return;

      addClickHandlersToElement(ref.current, () => {
        messageService.publish('article-model', {});
      });

      // custom scrolling behaviour on pinned articles (series as well)
      // add more when needed (probably different query selector)
      const widgetContainer = pinnedWidget
        ? (document.querySelector('.container-responsive-text') as HTMLElement | null)
        : null;
      const anchors = ref.current?.getElementsByTagName<'a'>('a');

      if (anchors) {
        for (const a of anchors) {
          const link = a.getAttribute('href');

          if (!link || !link.startsWith('#')) {
            a.setAttribute('target', '_blank');
          } else {
            if (widgetContainer)
              a.addEventListener('click', event => {
                event.preventDefault();
                event.stopPropagation();

                const targetId = link.substring(1);
                const targetElement = ref.current?.querySelector(
                  `[id="${targetId}"]`,
                ) as HTMLElement | null;

                if (targetElement && widgetContainer) {
                  widgetContainer.scrollTo({
                    top: targetElement.offsetTop - widgetContainer.offsetTop,
                    behavior: 'smooth',
                  });
                }
              });
          }
        }
      }

      const tables = ref.current?.getElementsByTagName<'table'>('table');

      if (tables) {
        for (const a of tables) {
          try {
            addTableResponsiveDiv(a as HTMLTableElement);
          } catch (e) {
            console.error('Could not fix table', e);
          }
        }
      }

      if (anchors){
        for (const a of anchors){
          if (a.href && a.href.toLowerCase().endsWith(".pdf")){
            a.className = " btn btn-tertiary " + styles.downloadPdfButton
            a.innerHTML = a.innerText
          }
        }
      }

    }
    // eslint-disable-next-line
  }, [ref.current, xhtml, toggle]);

  useSubscription('article-model', message => {
    setToggle(false);
  });

  useEffect(() => {
    setToggle(true);
  }, [toggle]);

  return (
    <>
      {' '}
      <div ref={ref} dangerouslySetInnerHTML={{ __html: xhtml }} className={styles.article}></div>
      {toggle && <span />}
    </>
  );
}
