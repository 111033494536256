import DashboardViewControls from '@/components/dashboard/dashboard-view-controls';
import DashboardEditControls from '@/components/dashboard/dashboard-edit-controls';
import { DashboardAction, DashboardState } from '../store/dashboard-content-reducer';
import { MNIDashboard } from '@/model/preferences/myMNIPreferences';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardUserMenu from '../dashboard-user-menu';
import DashboardNotifications from '../dashboard-notifications';
import { faGlobe, faTableColumns } from '@fortawesome/free-solid-svg-icons';
import shareApi from '@/api/share-api';
import { toast } from 'react-toastify';
import { ActiveWidgetsForDashboard } from '../hooks/use-widgets-from-layouts';
import { analyticsService } from '@/services';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';

export default function Navbar({
  state,
  selectedDashboard,
  notificationSoundOn,
  activeWidgets,
  setNotificationSoundOn,
  setShowCreateDashboardModal,
  setShowDeleteDashboardModal,
  dispatch,
  addWidget,
}: {
  state: DashboardState;
  selectedDashboard?: MNIDashboard;
  notificationSoundOn: boolean;
  activeWidgets?: ActiveWidgetsForDashboard;
  setNotificationSoundOn: (on: boolean) => void;
  setShowCreateDashboardModal: (visible: boolean) => void;
  setShowDeleteDashboardModal: (visible: boolean) => void;
  dispatch: (a: DashboardAction) => void;
  addWidget: (widgetId: string) => void;
}) {
  const onDashboardNameChange = (s: string) => {
    dispatch({
      type: 'setCurrentDashboardName',
      name: s,
    });
  };

  const onDashboardSelect = (value: MNIDashboard) => {
    dispatch({
      type: 'selectDashboard',
      id: value.id,
    });
    setShowCreateDashboardModal(false);
  };

  const onDashboardShare = async (value: MNIDashboard) => {
    const copyValue = {
      name: value.name,
      layouts: value.layouts,
      widgetPreferences: value.widgetPreferences,
    };

    const id = await shareApi.getShareLink(copyValue);

    const shareString = window.location.href + `?share=${id}`;

    await navigator.clipboard.writeText(shareString);

    analyticsService.recordAnalyticsEvent(AnalyticsEvent.DASHBOARD_SHARED, {});

    return toast.info('Text copied into clipboard');
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-dark sticky-top">
      <div className="container-fluid">
        {/*  eslint-disable-next-line  */}
        <a className="navbar-brand" href="#">
          <img alt="logo" src={'/logo-white.svg'} height={'24'} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          style={{ height: '40px', fontFamily: 'Inter' }}
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {!state.loading && (
              <div className={'d-flex '}>
                <div className={'d-flex'}>
                  {state.selectedDashboard ? (
                    state.editing ? (
                      <>
                        <DashboardEditControls
                          dashboardName={selectedDashboard?.name || ''}
                          onDashboardNameChange={onDashboardNameChange}
                          onSaveDashboard={() => {
                            dispatch({ type: 'saveCurrentDashboard' });
                          }}
                          onAddWidget={addWidget}
                          activeWidgets={activeWidgets?.activeWidgets || []}
                        />
                      </>
                    ) : (
                      <>
                        <button
                          className={'btn btn-warning me-2 btn-sm'}
                          style={{ fontFamily: 'Inter' }}
                          onClick={() => window.open('https://mnimarkets.com', 'mni_website')}
                        >
                          <FontAwesomeIcon icon={faGlobe} />
                        </button>
                        <DashboardViewControls
                          dashboards={state.dashboards || []}
                          selectedDashboard={selectedDashboard}
                          onDelete={() => {
                            setShowDeleteDashboardModal(true);
                          }}
                          onEdit={() => {
                            dispatch({ type: 'editCurrentDashboard' });
                          }}
                          onDashboardCreate={() => setShowCreateDashboardModal(true)}
                          onDashboardShare={onDashboardShare}
                          onDashboardSelect={onDashboardSelect}
                        />
                      </>
                    )
                  ) : (
                    <button
                      className={'btn btn-outline-secondary me-2 btn-sm'}
                      style={{ fontFamily: 'Inter' }}
                      onClick={() => setShowCreateDashboardModal(true)}
                    >
                      <FontAwesomeIcon icon={faTableColumns} /> Create Dashboard
                    </button>
                  )}
                </div>
              </div>
            )}
          </ul>

          <ul className="navbar-nav mb-2 mb-lg-0 align-items-center">
            <DashboardNotifications
              notificationSoundOn={notificationSoundOn}
              setNotificationSoundOn={setNotificationSoundOn}
            />

            <DashboardUserMenu className={' ms-2'} showSettings />
          </ul>
        </div>
      </div>
    </nav>
  );
}
