import React, { useContext, useEffect, useState } from 'react';
import { GridLoader } from 'react-spinners';
import useSubscription from '@/hooks/use-subscription';
import useTimer from '@/hooks/use-timer';
import { Article } from '@/model/article';
import theme from '@/index.module.scss';
import PageControl from '@/components/shared/page-control';
import { articleApi, sectionApi } from '@/api';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { Section } from '@/model/news/section';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import useWidgetNotifications from '@/widgets/notifications/use-widget-notifications';
import SectionFilters from '@/components/scrollers/section-filters';
import { BaseScrollerState } from '@/widgets/widgets/scrollers/base-scroller';

interface ScrollerProps {
  section: string[];
  articles?: Article[];

  onArticleClick: Function;
  dark?: boolean;
  title?: JSX.Element;
  onArticlesChange: (articles?: Article[]) => void;
}

interface ScrollerState extends BaseScrollerState {}

export default function Scroller(props: React.PropsWithChildren<ScrollerProps>) {
  const [articles, setArticles] = useState<Article[]>();
  const [offset, setOffset] = useState<number>(0);
  // eslint-disable-next-line
  const [forbidden, setForbidden] = useState<boolean>(false);
  const [sections, setSections] = useState<Section[]>();
  const [includedSections, setIncludedSections] = useState<string[]>([]);

  const widgetContainer = useContext<WidgetContainer>(WidgetContainerContext);
  const { sendNotification } = useWidgetNotifications();

  const fetchData = (offset: number, clearCurrent: boolean) => {
    if (includedSections.length < 1) return;

    if (!sections) return;

    if (clearCurrent) setArticles(undefined);

    articleApi
      .getArticlesBySection(
        // eslint-disable-next-line
        includedSections.filter(value => sections.find(s => s.code == value)),
        {
          page: offset,
          limit: 15,
        },
      )
      .then(
        value => {
          setArticles(value.content);
          setForbidden(false);
        },
        reason => {
          setForbidden(true);
        },
      );
  };

  const setPage = (offset: number) => {
    setArticles(undefined);
    setOffset(offset);
    fetchData(offset, true);
  };

  useSubscription<Article>(
    MessageServiceConstants.NEWS_ARTICLE_TOPIC,
    (article: Article, headers) => {
      const sections: string[] = JSON.parse(headers.sections);

      console.debug('Received article', sections, article);

      if (sections.some(value => includedSections.includes(value))) {
        console.debug('Adding article');
        const a = articles || [];

        const i = articles?.findIndex(value => value.uri === article.uri);

        if (i == null || i < 0) {
          console.debug('Adding article');
          a.unshift(article);
          a.pop();
        } else {
          console.debug('Updating article', i);
          a[i] = article;
        }

        sendNotification({
          correlationId: `scroller-${article.uri}`,
          title: 'New Article',
          description: article.headline,
          durationMillis: 300_000,
          date: new Date(),
        });

        setArticles([...a]);
      }
    },
  );

  useEffect(() => {
    setOffset(0);
    setArticles(undefined);
    fetchData(offset, true);

    Promise.allSettled(props.section.map(sectionApi.getSectionByCode)).then(value => {
      setSections(
        value
          // eslint-disable-next-line
          .filter(r => r.status == 'fulfilled')

          .map(r => (r as PromiseFulfilledResult<Awaited<Section>>).value)
          .filter(s => s.flags.authorized),
      );
    });

    if (props.section.length <= 1) {
      setIncludedSections(props.section);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.section]);

  useEffect(() => {
    props.onArticlesChange(articles);
    // eslint-disable-next-line
  }, [articles]);

  useEffect(() => {
    widgetContainer.updateState<ScrollerState>({ includedSections });
    fetchData(offset, true);
    // eslint-disable-next-line
  }, [includedSections]);

  useEffect(() => {
    if (sections && sections?.length === 1) {
      setIncludedSections(sections.map(value => value.code));
    }
  }, [sections]);

  const titleChange = (s?: string) => {
    widgetContainer.updateState<ScrollerState>({ title: s });
  };

  useTimer(
    30000,
    () => {
      fetchData(offset, false);
    },
    false,
  );

  return (
    <div className={'container-element h-100 '}>
      <div className={'h-100 d-flex flex-column container-responsive-text '}>
        <PageControl
          offset={offset}
          onChange={setPage}
          title={
            sections && sections.length > 1 ? (
              <SectionFilters
                sections={sections}
                onFilteredSectionsChange={setIncludedSections}
                onFilteredDataNameChange={titleChange}
              />
            ) : undefined
          }
        />

        {!articles && (
          <div style={{ textAlign: 'center' }} className={'mt-5'}>
            <GridLoader color={theme.info} />
          </div>
        )}

        <div className={'flex-fill mt-3 overflow-auto '}>{props.children}</div>
      </div>
    </div>
  );
}
