import { KeyboardEvent, FocusEvent, useState, useEffect } from 'react';

import { DataSeriesEntryForecast } from '@/model/calendar/data-series-entry-forecast';
import { DataSeriesEntry } from '@/model/calendar/data-series-entry';
import styles from '@/components/shared/forecast-editor/index.module.scss';
import { analyticsService, userForecastService } from '@/services';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';

export default function ForecastEditor({
  entry,
  date,
  onChange,
}: {
  entry: DataSeriesEntry;
  date: string;
  onChange: (forecast?: DataSeriesEntryForecast) => void;
}) {
  const [forecast, setForecast] = useState<DataSeriesEntryForecast | undefined>(entry.forecast());
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    setForecast(entry.forecast());
    setEditing(false);
  }, [entry, date]);

  function setUserValue(value: string | null): boolean {
    value = value?.trim() || null;

    if (value === null || value === '' || isNaN(parseFloat(value))) {
      removeUserForecast();
      return false;
    }

    value = `${parseFloat(value)}`;

    let entryForecast = entry.addForecast({
      source: 'USER',
      value,
    });

    setForecast(entryForecast);

    userForecastService
      .setForecastForSeriesEntry(date, entry.dataSeriesId, entryForecast)
      .then(() => {
        onChange(entryForecast);
      });

    return true;
  }

  const onKey = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      (event.target as HTMLElement).blur();
      const value = (event.target as HTMLFormElement).value;

      setUserValue(value);

      setEditing(false);
    }
  };

  const removeUserForecast = () => {
    console.log('Removing user forecast');

    entry.removeUserForecast();

    setForecast(entry.forecast());

    userForecastService.removeForecastForSeriesEntry(date, entry.dataSeriesId).then(() => {
      onChange();

      setEditing(false);
    });
  };

  const onBlur = (event: FocusEvent<HTMLElement>) => {
    const value = (event.target as HTMLFormElement).value;

    setUserValue(value);

    analyticsService.recordAnalyticsEvent(AnalyticsEvent.HSC_FORECAST_EDITED, {
      dataSeriesId: entry.dataSeriesId,
      dataSeriesName: entry.display,
    });

    setEditing(false);
  };

  return (
    <>
      {editing || (
        <small className={`text-text-dark`} onClick={() => setEditing(true)}>
          {forecast?.value || <span className={styles.emptyForecast} />}
        </small>
      )}

      {editing && (
        <input
          autoFocus={true}
          type={'text'}
          step={'0.001'}
          size={3}
          defaultValue={forecast?.value}
          onBlur={event => onBlur(event)}
          onKeyDown={event => onKey(event)}
          className={styles.forecastEditor}
        />
      )}
      {forecast?.source === 'USER' && (
        <span
          className={` ms-1 ${styles.removeForecastButton}`}
          onClick={() => removeUserForecast()}
        >
          ✕
        </span>
      )}

      <div
        className={forecast?.source === 'USER' ? 'text-success' : 'text-secondary'}
        style={{ fontSize: '0.6em' }}
      >
        {/* eslint-disable-next-line  */}
        {forecast?.source == 'MNI' ? '' : forecast?.source}
      </div>
    </>
  );
}
