import { Button, Modal } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { Document, Page, Outline, pdfjs } from 'react-pdf';
import {
  faXmark,
  faCaretLeft,
  faCaretRight,
  faChevronDown,
  faChevronUp,
  faListUl,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import './pdf-viewer.css';
import useIsMobile from '@/hooks/use-is-mobile';

let pdfClickHandler = (s: string) => {};
let imageClickHandler = (s: string, e: Callback) => {};

type Callback = () => void;
const listener = (cb: Callback) => {
  return (e: Event) => {
    let et = e.target as HTMLElement;
    let target = et.closest('a') as HTMLElement;
    let image = et.getElementsByTagName('img');
    if (target) {
      // if the click was on or within an <a>
      // then based on some condition...
      if (
        target.getAttribute('href')?.endsWith('.pdf') &&
        (target.getAttribute('href')?.includes('marketnews.com') ||
          target.getAttribute('href')?.includes('mnimarkets.com'))
      ) {
        e.preventDefault(); // tell the browser not to respond to the link click
        e.stopPropagation();
        // then maybe do something else
        pdfClickHandler(target.getAttribute('href') || '');
      }
    } else if (image.length > 0) {
      imageClickHandler(image[0].src, cb);
    }
  };
};

export function addClickHandlersToElement(element: HTMLElement, cb: Callback) {
  element.addEventListener('click', listener(cb), false);
}

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

export function ImageViewer() {
  const [show, setShow] = useState<boolean>(false);
  const [callback, setCallback] = useState<{ c: Callback }>({ c: () => {} });
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    imageClickHandler = async (s: string, e: Callback) => {
      setTimeout(() => {
        setShow(true);
        setUrl(s);
        setCallback({ c: e });
      }, 1);
    };

    return () => {
      imageClickHandler = () => {};
    };
  }, []);

  useEffect(() => {
    if (!show) {
      callback.c();
    }
    // eslint-disable-next-line
  }, [show]);

  const close = () => {
    setShow(false);
    setUrl('');
  };

  return (
    <div key={url} className={'d-none'}>
      <Modal show={show} onHide={close} size={'lg'} backdrop={true} key={url}>
        <Modal.Body className={'h-100 w-100'}>
          <img src={url} className={'w-100'} alt={url} />
        </Modal.Body>
      </Modal>
      {!show && <span />}
    </div>
  );
}

export default function PdfViewer() {
  const [show, setShow] = useState<boolean>(false);
  const [showTableOfContents, setShowTableOfContents] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [hasTOC, setHasTOC] = useState(false);
  const [isScrolled, setIsScrolled] = useState<string | undefined>('top');
  const isLargeScreen = useIsMobile(991);
  const isMobile = useIsMobile(767);

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const elementRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(100);

  function onDocumentLoadSuccess(pdf: pdfjs.PDFDocumentProxy) {
    setNumPages(pdf.numPages);
    pdf.getOutline().then(outline => {
      if (outline && outline.length > 0) {
        setHasTOC(true);
      } else {
        setHasTOC(false);
      }
    });
  }

  const ScrollIndicator = ({ direction, onClick }: { direction: string; onClick: () => void }) => {
    return (
      <div
        className="bg-bg-300 py-2 d-flex align-items-center justify-content-center text-text-dark"
        role="button"
        onClick={onClick}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClick();
          }
        }}
        style={{ cursor: 'pointer' }}
        tabIndex={0}
        aria-label={`Scroll to ${direction} section`}
      >
        <FontAwesomeIcon
          icon={direction === 'up' ? faChevronUp : faChevronDown}
          width={20}
          height={20}
        />
      </div>
    );
  };

  const handleScrollUp = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollTop - 250,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollDown = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollTop + 250,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      if (!container) return;

      const { scrollHeight, clientHeight, scrollTop } = container;

      const maxScrollTop = scrollHeight - clientHeight;
      const isScrolledToEnd = scrollTop >= maxScrollTop - 20;
      const isAtTop = scrollTop < 20;

      if (isScrolledToEnd) setIsScrolled('end');
      else if (isAtTop) setIsScrolled('top');
      else setIsScrolled(undefined);
    };

    if (show && container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [show]);

  useEffect(() => {
    pdfClickHandler = (s: string) => {
      setShow(true);
      setUrl(s);
    };

    const updateWidth = () => {
      if (elementRef.current) {
        setWidth(elementRef.current.getBoundingClientRect().width);
      }
    };
    window.addEventListener('resize', updateWidth);

    return () => {
      pdfClickHandler = () => {};
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (elementRef.current) {
      setWidth(elementRef.current.getBoundingClientRect().width);
    }
    setPageNumber(1);
    // eslint-disable-next-line
  }, [elementRef.current, url, show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size={'xl'}
      backdrop={true}
      fullscreen={'lg-down'}
    >
      <Modal.Header
        className={'sticky-top justify-content-between'}
        style={{ backgroundColor: 'var(--bs-bg-gray)' }}
      >
        <div className="d-flex align-items-center">
          <Button
            onClick={() => setPageNumber(pageNumber - 1)}
            variant={'link'}
            size={'sm'}
            // eslint-disable-next-line
            disabled={pageNumber == 1}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </Button>
          <span>
            {pageNumber} of {numPages}
          </span>
          <Button
            onClick={() => setPageNumber(pageNumber + 1)}
            variant={'link'}
            size={'sm'}
            // eslint-disable-next-line
            disabled={pageNumber == numPages}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </Button>
        </div>
        <span>
          {hasTOC && (
            <button
              className={'btn btn-link link-info text-text-dark btn-sm align-items-center'}
              onClick={() => setShowTableOfContents(!showTableOfContents)}
            >
              <FontAwesomeIcon icon={faListUl} className="me-1" />
              {showTableOfContents ? (
                <FontAwesomeIcon className="align-middle" size="xs" icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon className="align-middle" size="xs" icon={faChevronDown} />
              )}
            </button>
          )}
          <button
            className={'btn btn-link link-info text-text-dark btn-sm'}
            onClick={() => setShow(false)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </span>
      </Modal.Header>
      <Modal.Body className={'h-100 bg-bg-gray'} style={{ minHeight: '100px' }}>
        <div className={'viewer h-100 w-100 d-flex gap-3 position-relative'} ref={elementRef}>
          <div className="position-relative" style={{ width: isMobile ? '100px' : '200px' }}>
            <div
              className="position-sticky overflow-scroll hidden-scrollbar"
              ref={containerRef}
              style={{
                width: isMobile ? '80px' : '180px',
                height: isLargeScreen ? 'calc(100vh - 96px)' : 'calc(100vh - 154px)',
                maxHeight: '100%',
              }}
            >
              <div className="position-sticky top-0 z-3">
                {isScrolled !== 'top' && (
                  <ScrollIndicator direction="up" onClick={handleScrollUp} />
                )}
              </div>
              <Document
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
                className="w-100 flex-grow-0"
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div key={`page_${index + 1}`} className="py-2 bg-bg-200 position-sticky">
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      className="bg-bg-200 d-flex justify-content-center"
                      scale={isMobile ? 0.1 : 0.2}
                      onClick={() => setPageNumber(index + 1)}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        color:
                          pageNumber === index + 1 ? 'var(--bs-primary-700)' : 'var(--bs-bg-dark)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                      }}
                    >
                      {index + 1}
                    </div>
                  </div>
                ))}
              </Document>
              <div className="position-sticky bottom-0">
                {isScrolled !== 'end' && (
                  <ScrollIndicator direction="down" onClick={handleScrollDown} />
                )}
              </div>
            </div>
          </div>

          <div
            className="position-relative"
            style={{ maxWidth: `calc(${width}px - ${isMobile ? 100 : 200}px)` }}
          >
            <Document
              file={url}
              className={` ${showTableOfContents ? 'position-static' : 'position-absolute overflow-hidden h-25'}`}
            >
              <Outline onItemClick={item => setPageNumber(item.pageNumber)} />
            </Document>
            <Document file={url} onItemClick={item => setPageNumber(item.pageNumber)}>
              <Page pageNumber={pageNumber} width={isMobile ? width - 100 : width - 200} />
            </Document>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
