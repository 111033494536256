import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  LocalWidgetRouterContext,
  LocalWidgetRouterState,
} from '@/widgets/widget-router/local-widget-router';
import { NewsWidget } from '@/widgets/news-widget';
import DefaultWidgetContainerContext from '@/widgets/widget-container/default-widget-container-context';
import HasWidgetPermissions from '@/widgets/widget-permissions/has-widget-permissions';

export default function ReactWidgetRouter({
  children,
  widget,
}: React.PropsWithChildren<{ widget: NewsWidget }>) {
  console.log('react widget router');

  document.title = widget.defaultTitle || 'MNI';
  return (
    <LocalWidgetRouterContext.Provider
      value={
        {
          basePath: widget.id,
          useLocal: false,
        } as LocalWidgetRouterState
      }
    >
      <DefaultWidgetContainerContext widget={widget}>
        <HasWidgetPermissions widget={widget}>
          <Outlet />
        </HasWidgetPermissions>
      </DefaultWidgetContainerContext>
    </LocalWidgetRouterContext.Provider>
  );
}
