import { preferencesApi } from '@/api';
import React, { useEffect, useState } from 'react';
import { NewsWidget } from '@/widgets/news-widget';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { useSearchParams } from 'react-router-dom';

export default function DefaultWidgetContainerContext({
  widget,
  children,
}: React.PropsWithChildren<{ widget: NewsWidget }>) {
  const [state, setState] = useState<any>({});
  const [searchParams] = useSearchParams();
  const [preferences, setPreferences] = useState<any>(undefined);

  const preferencesKey = () => `widget-${widget.id}`;

  useEffect(() => {
    setPreferences({});
    setState(widget.initialState?.() ?? {});
    setPreferences(undefined);
    const paramsObject = Object.fromEntries(searchParams.entries());
    if (paramsObject) {
      let params: any = { ...paramsObject };
      if (paramsObject.includedSections) {
        params.includedSections = paramsObject.includedSections.split(',');
      }
      setPreferences(params);
    } else {
      preferencesApi.getPreferences(preferencesKey()).then(value => {
        setPreferences(value || {});
      });
    }
    // eslint-disable-next-line
  }, [widget]);

  useEffect(() => {
    if (state.title) {
      document.title = state.title;
    } else document.title = widget.defaultTitle || 'MNI';
    // eslint-disable-next-line
  }, [state]);

  return (
    <WidgetContainerContext.Provider
      value={{
        get widgetId(): string | undefined {
          return widget.id;
        },
        getPreferences<T>(): T | undefined {
          return preferences;
        },
        setPreferences<T>(state: T): void {
          setPreferences(state);
          preferencesApi
            .overwritePreferences(preferencesKey(), state)
            .then(value => console.log('saved preferences', state));
        },
        setState<T>(state: T): void {
          setState(state);
        },
        updatePreferences<T>(state: Partial<T>): void {
          setPreferences((prev: any) => ({ ...prev, state }));
          preferencesApi
            .upsertPreferences(preferencesKey(), state)
            .then(value => console.log('saved preferences', {}));
        },
        updateState<T>(state: Partial<T>): void {
          setState((prev: any) => ({ ...prev, state }));
        },
        getState() {
          return state;
        },
        close() {
          window.close();
        },
      }}
    >
      {preferences && children}
    </WidgetContainerContext.Provider>
  );
}
