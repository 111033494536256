import dayjs, { Dayjs } from 'dayjs';

import DateFormat from '@/components/shared/date-format';

export default function AgedDateFormat({
  format,
  fullFormat,
  date,
  scale,
}: {
  format: string;
  fullFormat?: string;
  date: string | Date | Dayjs | undefined;
  scale?: number;
}) {
  if (!scale) scale = 1;

  const today = dayjs(dayjs());
  const itemDate = dayjs(date);

  const isToday = today.isSame(itemDate, 'day');
  const isYesterday = today.subtract(1, 'day').isSame(itemDate, 'day');
  const days = today.diff(itemDate, 'minute') * scale;

  let displayDate = '';

  if (isYesterday) {
    displayDate = 'Yesterday';
  } else {
    displayDate = itemDate.fromNow(); // E.g., Dec 4
  }

  let c = '';

  //if (days < 60) c = 'text-warning-light';
  //else
  if (days < 180) c = 'text-tertiary';

  return (
    <>
      <span className={c} style={{ fontFamily: 'Inter', fontWeight: 700 }}>
        {fullFormat && isYesterday ? 'Yesterday, ' : ''}
        <DateFormat
          format={isToday || isYesterday || !fullFormat ? format : fullFormat}
          date={date}
        />
      </span>
      {!isToday && !fullFormat && (
        <p style={{ fontFamily: 'Inter', fontSize: '11px', fontWeight: 600 }}>{displayDate}</p>
      )}
    </>
  );
}
